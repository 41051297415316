import React, { useEffect, useState } from "react";
import "simplebar-react/dist/simplebar.min.css";
import Folder from "../../assets/img/icons/folder.png";
import Retry from "../../assets/img/icons/retry.png";
import Filter from "../../assets/img/icons/filter.png";
import Reset from "../../assets/img/icons/reset.png";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ProgressBar from "../../helpers/ProgressBar";
import UploadTable from "../../helpers/UploadTable";
import { Chip, Tooltip } from "@mui/material";
import {
  UPLOAD_BATCH_SIZE,
  getUploadStatusStyles,
} from "../../helpers/Constants";
import axios from "axios";
import { useGlobal } from "../../GlobalContext";
import ExifReader from "exifreader";
import { convertToEpoch } from "../../helpers/Utils";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  background:
    "linear-gradient(90deg, rgba(21, 71, 93, 1) 0%, rgba(84, 193, 165, 1) 100%)",
  borderRadius: "10px",
  textTransform: "none",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "13px",

  "&:hover": {
    background:
      "linear-gradient(90deg, rgba(0, 99, 119, 1)0%, rgba(84, 193, 165, 0.7) 100%)",
  },
}));

const GCPImagesUploadPage = () => {
  const { requestData } = useGlobal();
  const [fileName, setFileName] = useState("");
  const [progress, setProgress] = useState(0);
  const [request, setRequest] = useState(requestData[0]?.id);

  const [emptyWorkOrderField, setEmptyWorkOrderField] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [failFilter, setFailFilter] = useState(false);
  const [originalData, setOrginalData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  let progressCtr = 0;

  useEffect(() => {
    document.title = "Upload Images — " + process.env.REACT_APP_PAGE_TITLE;

    window.appComponent.setState({
      loaderVisible: false,
    });
  }, []);

  const handleFiles = async (e) => {
    const files = e.target.files;

    if (files[0]?.webkitRelativePath?.split("/")[0]) {
      setFileName(files[0].webkitRelativePath.split("/")[0]);
    }

    const imageFiles = Array.from(files).filter((file) =>
      file.type.startsWith("image/")
    );

    const processImage = (file) =>
      new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const arrayBuffer = e.target.result;
          try {
            const tags = ExifReader.load(arrayBuffer, { expanded: false }); // Load only required tags
            const make = tags?.Make?.description || "Unknown";
            const dateTime = tags?.DateTime?.description || "Unknown";
            let name = file.name;

            if (dateTime !== "Unknown") {
              name = `${make}_${convertToEpoch(dateTime)}_${file.name}`;
            }

            resolve({ name, size: file.size, file });
          } catch (error) {
            console.error("Error reading EXIF data:", error);
            resolve({ name: file.name, size: file.size, file });
          }
        };
        reader.readAsArrayBuffer(file);
      });

    const batchProcessImages = async (files, batchSize) => {
      const results = [];
      for (let i = 0; i < files.length; i += batchSize) {
        const batch = files.slice(i, i + batchSize);
        const batchResults = await Promise.all(
          batch.map((file) => processImage(file))
        );
        results.push(...batchResults);
      }
      return results;
    };

    // Process images in batches of 10
    const tableRowsData = await batchProcessImages(
      imageFiles,
      UPLOAD_BATCH_SIZE
    );

    const namesArray = tableRowsData.map((data) => data.name);
    const wrappedObject = { image_list: namesArray };

    const workOrderVal = requestData[0]?.id;

    if (workOrderVal) {
      setEmptyWorkOrderField(false);
      window.apiHelper
        .getUniqueImages(workOrderVal, JSON.stringify(wrappedObject))
        .then((result) => {
          if (result) {
            const filteredData = tableRowsData.filter(
              (item) => !result[item.name]
            );
            if (filteredData.length > 0) {
              setTableData(filteredData);
              setOrginalData(filteredData);
              setTimeout(() => {
                alert("Table updated! Click Submit button to upload");
              }, 500);
            } else {
              alert("All Images in the folder have already been uploaded");
              setFileName("");
            }
          } else {
            alert("Please enter a correct work order value");
            setFileName("");
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      setEmptyWorkOrderField(true);
      setFileName("");
    }
  };

  const processImagesInGroups = async (imageList, workorder, groupSize) => {
    const list = imageList[0]?.status
      ? imageList.filter((row) => row.status === 0)
      : imageList;

    const resultArray = [];
    if (list.length > 0) {
      setDisabled(true);
      for (let i = 0; i < list.length; i += groupSize) {
        const group = list.slice(i, i + groupSize);
        const promises = group.map(async (image) => {
          const apiResponse = await window.apiHelper.getImageUploadURL(
            workorder,
            image.name
          );

          try {
            const response = await axios.put(
              apiResponse.upload_url,
              image.file,
              {
                headers: {
                  "Content-Type": "application/octet-stream",
                },
              }
            );

            if (response.status === 200) {
              const data = {
                request_id: workorder,
                filename: image.name,
              };
              try {
                await window.apiHelper.getImageIngestInitiate(data);

                const resultObject = {
                  name: image.name,
                  size: image.size,
                  status: 1,
                  action: false,
                  file: image.file,
                };

                resultArray.push(resultObject);
                progressCtr = progressCtr + 1;
                const progressValue = Math.round(
                  (progressCtr / originalData.length) * 100
                );
                setProgress(progressValue);

                progressValue === 100 && setDisabled(false);
              } catch (e) {
                console.error("Error uploading file:", e);

                // Create an object for the result
                const resultObject = {
                  name: image.name,
                  size: image.size,
                  status: 0,
                  action: true,
                  file: image.file,
                };

                // Push the result object to the result array
                resultArray.push(resultObject);
              }
            } else {
              alert("File upload failed.");
            }
          } catch (e) {
            console.error("Error uploading file:", e);

            // Create an object for the result
            const resultObject = {
              name: image.name,
              size: image.size,
              status: 0,
              action: true,
              file: image.file,
            };

            // Push the result object to the result array
            resultArray.push(resultObject);
          }
        });

        // Wait for all promises in the current group to complete
        await Promise.all(promises);
      }
      return resultArray;
    } else {
      return imageList;
    }
  };

  const loadfiles = () => {
    const workOrderVal = requestData[0]?.id;
    if (workOrderVal === "") {
      setEmptyWorkOrderField(true);
    } else {
      setProgress(0.1);
      setEmptyWorkOrderField(false);
      processImagesInGroups(tableData, workOrderVal, UPLOAD_BATCH_SIZE)
        .then((resultArray) => {
          setTableData(resultArray);
          setOrginalData(resultArray);
          setTimeout(() => {
            alert("Upload Complete");
          }, 500);
        })
        .catch((error) => {
          console.error("Error processing images:", error);
        });
    }
  };

  const handleResetFiltersState = () => {
    setFailFilter(false);
    setTableData(originalData);
  };

  const retryAllFailed = () => {
    const workOrderVal = requestData[0]?.id;

    if (workOrderVal === "") {
      setEmptyWorkOrderField(true);
    } else {
      progressCtr = Math.round((progress * originalData.length) / 100);
      setEmptyWorkOrderField(false);
      processImagesInGroups(tableData, workOrderVal, UPLOAD_BATCH_SIZE)
        .then((resultArray) => {
          if (resultArray === tableData) {
            alert("No failures to retry");
          } else {
            setTableData(resultArray);
            setTimeout(() => {
              alert("Upload Complete");
            }, 500);
          }
        })
        .catch((error) => {
          console.error("Error processing images:", error);
        });
    }
  };

  return (
    <div className="upload-view">
      <div className="header-main">
        <span>Upload Images</span>
      </div>

      <div id="upload-panel">
        <div className="upload-header">
          <div className="search">
            <label>Request ID:</label>&nbsp;
            <input
              type="text"
              className="text-input"
              placeholder={requestData[0]?.id}
              id="work-order"
              disabled
            />
          </div>

          <div className="search">
            <label>Line Number:</label>&nbsp;
            <input
              type="text"
              className="text-input"
              placeholder={requestData[0]?.line_number}
              id="line-number"
              disabled
            />
          </div>

          <div>
            <button
              type="button"
              className="upload-button"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("files-field").click();
              }}
            >
              <img
                src={Folder}
                alt="Image Alt Text"
                className="upload-page-button-img"
              />
              <span>{fileName === "" ? "Select Folder" : fileName}</span>
            </button>
            <input
              id="files-field"
              webkitdirectory=""
              type="file"
              className="d-none"
              onChange={handleFiles}
            />
          </div>

          <div>
            <ColorButton
              variant="contained"
              onClick={loadfiles}
              disabled={disabled}
            >
              Submit
            </ColorButton>
          </div>

          {emptyWorkOrderField && (
            <span className="requiredText">
              <sup>*</sup> Request Id is required
            </span>
          )}
        </div>

        <div className="upload-progress">
          {progress !== 0 && <ProgressBar progress={progress} />}
        </div>

        <div className="upload-filters">
          <button
            type="button"
            className="retry-all-button"
            onClick={retryAllFailed}
          >
            <img src={Retry} alt="Image Alt Text" className="button-img" />
            <span>
              {`Retry all`}
              <Chip
                label="failed"
                style={getUploadStatusStyles(0)}
                size="small"
                sx={{ marginLeft: "5px" }}
              />
            </span>
          </button>

          <button
            type="button"
            className="filter-all-button"
            onClick={() => {
              setFailFilter(!failFilter);
            }}
          >
            <img src={Filter} alt="Image Alt Text" className="button-img" />
            <span>
              {`Filter By`}
              <Chip
                label="failed"
                style={getUploadStatusStyles(0)}
                size="small"
                sx={{ marginLeft: "5px" }}
              />
            </span>
          </button>

          <Tooltip title="Reset Filters" arrow placement="top">
            <div className="button-dropdown-container">
              <button
                className="reset-button"
                onClick={handleResetFiltersState}
              >
                <img src={Reset} alt="reset" className="button-img-reset" />
              </button>
            </div>
          </Tooltip>
        </div>

        <div>
          <UploadTable
            length={tableData.length}
            tableData={tableData}
            failFilter={failFilter}
            setTableData={setTableData}
            workOrderVal={request}
            progress={progress}
            setProgress={setProgress}
            originalData={originalData}
            setDisabled={setDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default GCPImagesUploadPage;
