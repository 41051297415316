import * as React from "react";
import {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useContext,
} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Tooltip } from "@mui/material";
import Save from "../../assets/img/icons/save-row-2.png";
import Cross from "../../assets/img/icons/cancel.png";
import Edit from "../../assets/img/icons/edit-row.png";
import Delete from "../../assets/img/icons/delete-row.png";
import Info from "../../assets/img/icons/info.png";
import { showNotificationToast } from "../../helpers/Messages";
import AuthContext from "../auth/AuthContext";

const InfoTable = forwardRef((props, ref) => {
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [editableKey, setEditableKey] = useState("");
  const [editableValue, setEditableValue] = useState("");
  const [isNewRow, setIsNewRow] = useState(false);
  const [changesMade, setChangesMade] = useState(false);

  const authContext = useContext(AuthContext);

  useEffect(() => {
    props.setRows(props.rows);
  }, [props.rows]);

  // Expose the addRow method to the parent using useImperativeHandle
  useImperativeHandle(ref, () => ({
    addRow() {
      props.setRows([{ "": "" }, ...props.rows]);
      setEditingRowIndex(0);
      setEditableKey("");
      setEditableValue("");
      setIsNewRow(true);
      setChangesMade(true);
    },
  }));

  const handleEditRow = (index) => {
    const row = props.rows[index];
    const key = Object.keys(row)[0];
    const value = Array.isArray(row[key]) ? row[key][0] : row[key];

    setEditingRowIndex(index);
    setEditableKey(key);
    setEditableValue(value);
    setIsNewRow(false);
    setChangesMade(true);
  };

  const handleSaveRow = (index) => {
    const updatedRows = [...props.rows];
    const originalRow = props.rows[index];
    const originalKey = Object.keys(originalRow)[0];
    const originalValue = originalRow[originalKey];

    // Determine if the row was originally an array or a string
    const updatedValue = Array.isArray(originalValue)
      ? [editableValue, originalValue[1]]
      : editableValue;

    updatedRows[index] = {
      [editableKey || originalKey]: updatedValue,
    };

    props.setRows(updatedRows);
    setEditingRowIndex(null);
    setIsNewRow(false);
    setChangesMade(true);
  };

  const handleCancelEdit = () => {
    if (isNewRow) {
      props.setRows(props.rows.slice(1));
    }
    setEditingRowIndex(null);
    setIsNewRow(false);
    setChangesMade(false); // Reset changes made if canceling
  };

  const handleDeleteRow = (index) => {
    const updatedRows = props.rows.filter((_, i) => i !== index);
    props.setRows(updatedRows);
    setChangesMade(true);
  };

  const handleKeyChange = (e) => {
    setEditableKey(e.target.value);
  };

  const handleValueChange = (e) => {
    setEditableValue(e.target.value);
  };

  const updateTable = async (rows) => {
    props.setLoading(true);
    try {
      const updated_template_data = await window.apiHelper.updateInfoTableData(
        props.selectedRequestId,
        props.selectedStructureId,
        props.selectedOption,
        rows
      );

      if (updated_template_data) {
        props.setShowOptions(false);
        showNotificationToast("Updated Successfully!");
        setChangesMade(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      props.setLoading(false);
    }
  };

  const handleSaveChanges = () => {
    updateTable(props.rows);
  };

  return (
    <div>
      <Paper
        sx={{
          height: props.tableHeight,
        }}
      >
        <TableContainer sx={{ height: "100%" }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: "#eef1f7",
                    width: "25%",
                    fontWeight: "bold",
                    fontFamily: "inherit",
                  }}
                >
                  Label
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: "#eef1f7",
                    width: "50%",
                    fontWeight: "bold",
                    fontFamily: "inherit",
                  }}
                >
                  Information
                </TableCell>
                {authContext.permissions.includes("can_edit_info") && (
                  <TableCell
                    align="center"
                    sx={{
                      backgroundColor: "#eef1f7",
                      width: "25%",
                      fontWeight: "bold",
                      fontFamily: "inherit",
                    }}
                  >
                    Actions
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.rows.map((row, index) => {
                const key = Object.keys(row)[0];
                const value = Array.isArray(row[key]) ? row[key][0] : row[key];
                const isHighlighted = Array.isArray(row[key])
                  ? row[key][1]
                  : false;

                return (
                  <TableRow key={index}>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      sx={{
                        maxWidth: 100,
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        backgroundColor: isHighlighted ? "#FFF8DC" : "inherit",
                        width: "25%",
                        fontFamily: "inherit",
                      }}
                    >
                      {editingRowIndex === index ? (
                        <TextField
                          size="small"
                          label="Key"
                          variant="standard"
                          value={editableKey}
                          onChange={handleKeyChange}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handleSaveRow(index); // Trigger save on Enter key
                          }}
                          sx={{
                            width: "100%",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                          }}
                          multiline
                        />
                      ) : (
                        key
                      )}
                    </TableCell>
                    {editingRowIndex === index ? (
                      <>
                        <TableCell
                          align="center"
                          sx={{
                            maxWidth: 100,
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                            width: "50%",
                            fontFamily: "inherit",
                          }}
                        >
                          <TextField
                            size="small"
                            label="Value"
                            variant="standard"
                            value={editableValue}
                            onChange={handleValueChange}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") handleSaveRow(index); // Trigger save on Enter key
                            }}
                            sx={{
                              width: "100%",
                              wordWrap: "break-word",
                              whiteSpace: "normal",
                            }}
                            multiline
                          />
                        </TableCell>
                        <TableCell align="center" sx={{ width: "25%" }}>
                          <Tooltip title="Save" arrow placement="top">
                            <button
                              onClick={() => handleSaveRow(index)}
                              className="save-btn"
                            >
                              <img
                                src={Save}
                                alt="save"
                                style={{ width: "17.5px" }}
                              />
                            </button>
                          </Tooltip>

                          <Tooltip title="Close" arrow placement="top">
                            <button
                              onClick={handleCancelEdit}
                              className="save-btn"
                            >
                              <img
                                src={Cross}
                                alt="close"
                                style={{ width: "17.5px" }}
                              />
                            </button>
                          </Tooltip>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell
                          align="center"
                          sx={{
                            maxWidth: 100,
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                            backgroundColor: isHighlighted
                              ? "#FFF8DC"
                              : "inherit",
                            width: "50%",
                            fontFamily: "inherit",
                          }}
                        >
                          {value}
                        </TableCell>
                        {authContext.permissions.includes("can_edit_info") && (
                          <TableCell align="center" sx={{ width: "25%" }}>
                            <Tooltip title="Edit" arrow placement="top">
                              <button
                                onClick={() => handleEditRow(index, key)}
                                className="save-btn"
                              >
                                <img
                                  src={Edit}
                                  alt="edit"
                                  style={{ width: "17.5px" }}
                                />
                              </button>
                            </Tooltip>

                            <Tooltip title="Delete" arrow placement="top">
                              <button
                                onClick={() => handleDeleteRow(index)}
                                className="save-btn"
                              >
                                <img
                                  src={Delete}
                                  alt="delete"
                                  style={{ width: "17.5px" }}
                                />
                              </button>
                            </Tooltip>
                          </TableCell>
                        )}
                      </>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <div className="info-footer">
        {props.rows.length > 0 && (
          <Tooltip
            title="Yellow colour symbolizes information is assumed due to insufficient evidence"
            arrow
            placement="top"
          >
            <button className="info-btn">
              <img src={Info} alt="reset" style={{ width: "25px" }} />
            </button>
          </Tooltip>
        )}
        {changesMade && (
          <button
            className="reset-button"
            onClick={handleSaveChanges}
            style={{ marginLeft: 0 }}
          >
            Save Changes
          </button>
        )}
      </div>
    </div>
  );
});

export default InfoTable;
