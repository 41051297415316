import React, { useState, useRef, useEffect, useContext } from "react";
import Sort from "../../assets/img/icons/sort.png";
import InfoTable from "./InfoTable";
import { Tooltip, Dialog, DialogContent, DialogTitle } from "@mui/material";
import AddRow from "../../assets/img/icons/add.png";
import Expand from "../../assets/img/icons/expand2.png";
import Shrink from "../../assets/img/icons/shrink2.png";
import Loader from "../../helpers/Loader";
import InfoDropdown from "../../helpers/InfoDropdown";
import { infoTabOptions } from "../../helpers/Constants";
import AuthContext from "../auth/AuthContext";

const Info = ({ selectedStructureId, selectedRequestId }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [tableType, setTableType] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [tableHeight, setTableHeight] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);

  const dropdownRef = useRef(null);
  const tableRef = useRef(null);
  const infoTableRef = useRef(null);

  const authContext = useContext(AuthContext);

  const getFirstNonEmptyValues = (obj) => {
    for (const key of Object.keys(obj)) {
      if (Array.isArray(obj[key]) && obj[key].length > 0) {
        return { key, values: obj[key] };
      }
    }
    return { key: null, values: [] };
  };

  const getKeyByValue = (obj, value) => {
    return Object.keys(obj).find((key) => obj[key] === value);
  };

  const fetchStructureMetaData = async () => {
    setLoading(true);
    try {
      const structure_metadata = await window.apiHelper.getStructureMetaData(
        selectedRequestId,
        selectedStructureId
      );
      if (getFirstNonEmptyValues(structure_metadata).values.length === 0) {
        setShowOptions(true);
        setRows([]);
        setTableType("");
      } else {
        setRows(getFirstNonEmptyValues(structure_metadata).values);
        setTableType(getFirstNonEmptyValues(structure_metadata).key);
        setSelectedOption(
          getKeyByValue(
            infoTabOptions,
            getFirstNonEmptyValues(structure_metadata).key
          )
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStructureMetaData();
  }, [showOptions, selectedStructureId]);

  const fetchTemplateTypes = async () => {
    setLoading(true);
    try {
      const template_types = await window.apiHelper.getTemplateTypes();
      if (template_types) {
        setDropdownOptions(template_types);
        setSelectedOption(template_types[0].id);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    showOptions && fetchTemplateTypes();
  }, [showOptions]);

  const fetchTemplateSchema = async () => {
    setLoading(true);
    try {
      const template_schema = await window.apiHelper.getTemplateSchema(
        selectedRequestId,
        selectedOption
      );

      if (template_schema) {
        setRows(template_schema);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    showOptions && selectedOption && fetchTemplateSchema();
  }, [showOptions, selectedOption]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleSortOptionSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleAddRow = () => {
    if (tableRef.current) {
      tableRef.current.addRow();
    }
  };

  useEffect(() => {
    if (infoTableRef.current) {
      setTableHeight(infoTableRef.current.clientHeight);
    }
  }, [rows]);

  const handlePopup = () => {
    setOpenPopup(!openPopup);
  };

  const renderHeader = (imgSrc) => (
    <div className="dropdown-header">
      <div className="dropdown-header-left">{renderDropdownOrTableType()}</div>
      <div className="dropdown-header-right">
        <button className="resize-button" onClick={handlePopup}>
          <img src={imgSrc} alt="expand" className="button-img-resize" />
        </button>
        {renderAddRowButton()}
      </div>
    </div>
  );

  const renderDropdownOrTableType = () => {
    return showOptions && authContext.permissions.includes("can_edit_info") ? (
      <>
        {tableType ? (
          <div>{tableType}</div>
        ) : (
          <>
            <div style={{ marginRight: "5px" }}>Select Preset: </div>
            <button className="preset-button" onClick={toggleDropdown}>
              <img src={Sort} alt="Image Alt Text" className="button-img" />
              <span>
                {dropdownOptions[selectedOption - 1]?.name === "Default"
                  ? "-------"
                  : dropdownOptions[selectedOption - 1]?.name}
              </span>
              {showDropdown && (
                <div className="dropdown-box">
                  <InfoDropdown
                    options={dropdownOptions}
                    optionSelected={handleSortOptionSelect}
                    toggleDropdown={toggleDropdown}
                    dropdownRef={dropdownRef}
                    selectedOption={selectedOption}
                  />
                </div>
              )}
            </button>
          </>
        )}
      </>
    ) : (
      <div>{tableType}</div>
    );
  };

  const renderAddRowButton = () => {
    return (
      authContext.permissions.includes("can_edit_info") && (
        <Tooltip title="Add New Row" arrow placement="top">
          <button onClick={handleAddRow} className="save-btn">
            <img src={AddRow} alt="add new row" style={{ width: "35px" }} />
          </button>
        </Tooltip>
      )
    );
  };

  const renderInfoTable = (tableHeightValue) => (
    <InfoTable
      ref={tableRef}
      rows={rows}
      setRows={setRows}
      selectedStructureId={selectedStructureId}
      selectedRequestId={selectedRequestId}
      selectedOption={selectedOption}
      setLoading={setLoading}
      setShowOptions={setShowOptions}
      tableHeight={tableHeightValue}
    />
  );

  return (
    <div style={{ position: "relative", height: "100%" }}>
      {loading && <Loader />}
      <div
        className="button-dropdown-container"
        ref={dropdownRef}
        style={{ width: "100%" }}
      >
        {renderHeader(Expand)}
      </div>
      <div ref={infoTableRef} style={{ height: "calc(100% - 100px)" }}>
        {renderInfoTable(tableHeight)}
      </div>

      <Dialog open={openPopup} onClose={handlePopup} maxWidth="xl" fullWidth>
        <div style={{ position: "relative" }}>
          {loading && <Loader />}

          <DialogTitle>{renderHeader(Shrink)}</DialogTitle>
          <DialogContent dividers>
            {renderInfoTable(tableHeight * 1.25)}
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default Info;
