import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { UploadStatusConstants, getUploadStatusStyles } from "./Constants";
import { Chip } from "@mui/material";
import Retry from "../assets/img/icons/retry.png";
import axios from "axios";

const columns = [
  { id: "name", label: "Image Name", align: "center", minWidth: 200 },
  { id: "size", label: "Image Size", align: "center", minWidth: 100 },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const UploadTable = ({
  length,
  tableData,
  setTableData,
  failFilter,
  workOrderVal,
  progress,
  setProgress,
  originalData,
  setDisabled,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  let val = Math.round((progress * originalData.length) / 100);

  React.useEffect(() => {
    failFilter && setTableData(tableData.filter((row) => row.status === 0));
  }, [failFilter]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const uploadFileToURL = async (uploadURL, file) => {
    try {
      const response = await axios.put(uploadURL, file, {
        headers: { "Content-Type": "application/octet-stream" },
      });
      return response.status === 200;
    } catch (error) {
      console.error("File upload failed:", error);
      throw new Error("File upload failed");
    }
  };

  const initiateImageIngest = async (workOrderVal, filename) => {
    try {
      const data = { request_id: workOrderVal, filename };
      await window.apiHelper.getImageIngestInitiate(data);
    } catch (error) {
      console.error("Image ingestion failed:", error);
      throw new Error("Image ingestion failed");
    }
  };

  const updateTableData = (row, tableData, setTableData) => {
    const replacementObject = {
      name: row.name,
      size: row.size,
      status: 1,
      action: false,
      file: row.file,
    };

    const newArray = tableData.map((item) =>
      item.name === row.name ? replacementObject : item
    );

    setTableData(newArray);
  };

  const handleRetry = async (row) => {
    if (!row.action) return;

    try {
      // Step 1: Get the upload URL
      const result = await window.apiHelper.getImageUploadURL(
        workOrderVal,
        row.name
      );

      // Step 2: Upload file
      await uploadFileToURL(result.upload_url, row.file);

      // Step 3: Initiate image ingestion
      await initiateImageIngest(workOrderVal, row.name);

      // Step 4: Update table data and progress
      updateTableData(row, tableData, setTableData);

      val = val + 1;
      const progressValue = Math.round((val / originalData.length) * 100);
      setProgress(progressValue);

      if (progressValue === 100) {
        setDisabled(false);
        setTimeout(() => alert("Upload Complete"), 750);
      }

      setTimeout(() => alert("Retry Successful"), 500);
    } catch (error) {
      console.error(error.message);
      alert("Retry Failed! Please try again.");
    }
  };

  return (
    <Paper
      sx={{
        width: "100%",
        position: "relative",
        height: "calc(100vh - 285px)",
      }}
    >
      <TableContainer sx={{ height: "92.5%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    background: "#EEF1F8",
                    fontFamily: "inherit",
                    fontWeight: 600,
                    fontSize: "14.5px",
                    textAlign: "center",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            maxWidth: column.minWidth,
                            color: "inherit",
                            fontFamily: "inherit",
                            fontWeight: 500,
                            textAlign: "center",
                          }}
                        >
                          {column.id === "size" ? (
                            `${(value / (1000 * 1024)).toFixed(2)} Mb`
                          ) : column.id === "status" ? (
                            <Chip
                              label={UploadStatusConstants[value]}
                              style={getUploadStatusStyles(value)}
                            />
                          ) : column.id === "action" ? (
                            <button
                              style={{
                                borderRadius: "15px",
                                padding: "5px 8px",
                                backgroundColor: value ? "white" : "#EBEBE4",
                                border: "1px solid #acacac",
                                boxShadow:
                                  "0px 0px 7px 0px rgba(0, 0, 0, 0.15)",
                                width: "35px",
                                cursor: value ? "pointer" : "not-allowed",
                              }}
                              onMouseEnter={(e) => {
                                if (value) {
                                  e.currentTarget.style.boxShadow =
                                    "0px 0px 7px 0px rgba(0, 0, 0, 0.5)";
                                }
                              }}
                              onMouseLeave={(e) => {
                                if (value) {
                                  e.currentTarget.style.boxShadow =
                                    "0px 0px 7px 0px rgba(0, 0, 0, 0.15)";
                                }
                              }}
                            >
                              <img
                                src={Retry}
                                alt="Image Alt Text"
                                className="upload-page-button-img"
                                onClick={() => handleRetry(row)}
                              />
                            </button>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="table-pagination-container">
        <div></div>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              margin: 0,
            },
            "& .MuiTablePagination-displayedRows": {
              margin: 0,
            },
          }}
        />
      </div>
    </Paper>
  );
};

export default UploadTable;
